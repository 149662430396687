import "./ToneTipTagsDisplay.scss";

type ToneTipTagsDisplayProps = {
  tags: string[];
};

const ToneTipTagsDisplay = ({ tags }: ToneTipTagsDisplayProps) => {
  if (!tags || tags.length === 0) {
    return null;
  }
  return (
    <div className="tags-container">
      {tags.map((tag, index) => {
        return (
          <span className="tag" key={`tag${index} - ${tag}`}>
            {tag}
          </span>
        );
      })}
    </div>
  );
};

export default ToneTipTagsDisplay;
