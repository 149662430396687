import "./ToneTipDetails.scss";
import EditButton from "../../assets/img/edit-tonetip-button.svg";
import ToneTipPreviewCard from "../ToneTipPreviewCard/ToneTipPreviewCard";
import ToneTipStatsCard from "../ToneTipStatsCard/ToneTipStatsCard";
import CommandButton from "../CommandButton/CommandButton";
import GreyBarsIcon from "../../assets/icons/grey-bars-icon.svg";
import CommentCard from "../CommentCard/CommentCard";
import { CommandI, CommentI } from "../../../Interfaces/ToneTip";
import { useToneTipContext } from "../../context/toneTipsContext";
import { useEffect, useState } from "react";
import { getToneTipComments } from "../../services/ToneTipServices";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { genWav } from "../../utils/tt-encoder/generateAudioFile";
import { useUserContext } from "../../context/userContext";
import AddCommentInput from "../AddCommentInput/AddCommentInput";
import ToneTipTagsDisplay from "../Tags/ToneTipTagsDisplay";

function ToneTipDetails() {
  const { handleEditToneTip, selectedToneTip } = useToneTipContext();
  const [comments, setComments] = useState<Array<CommentI>>([]);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false);
  const { currentUser } = useUserContext();
  const defaultValues: CommandI = {
    action: "Website",
    bgColor: "#03D4D2",
    text: "Default",
    textColor: "#ffffff",
    value: "",
    clicks: 0,
  };

  const onCommentDeleted = (id: string | undefined) => {
    setComments((prevState) => {
      return prevState.filter((comment) => comment.id !== id);
    });
  };

  const onCommentEdited = (id: string | undefined, newComment: string) => {
    const commentToEdit = comments?.filter((comment) => comment.id === id)[0];
    if (commentToEdit) {
      const commentToEditIndex = comments?.indexOf(commentToEdit);
      const tempComments = [...comments];
      commentToEdit.editedComment = true;
      commentToEdit.text = newComment;
      tempComments[commentToEditIndex] = commentToEdit;
      setComments(tempComments);
    }
  };

  const onCommentSent = (comment: CommentI) => {
    if (comments) {
      const tempComments = [...comments];
      tempComments.unshift(comment);
      setComments(tempComments);
    } else {
      setComments([comment]);
    }
  };

  const handleWAVFileDownload = async (f: any) => {
    if (selectedToneTip?.id) {
      const freq: number = parseInt(f);
      await genWav(
        selectedToneTip?.id,
        selectedToneTip.fileName || "",
        freq * 1000
      );
    }
  };

  const getAllToneTipComments = async () => {
    setCommentsLoading(true);
    if (selectedToneTip?.id) {
      let tempComments = await getToneTipComments(selectedToneTip.id);
      if (tempComments) {
        setComments(tempComments);
      }
    }
    setCommentsLoading(false);
  };

  useEffect(() => {
    getAllToneTipComments();
    return () => {
      setComments([]);
    };
  }, [selectedToneTip]);

  return (
    <div className="tone-tip-details-container">
      <div className="sticky-container">
        <div className="details-top-part">
          <span className="tone-tip-title">{selectedToneTip?.title}</span>
          <div className="tone-tip-action-btns-container">
            <DropdownButton
              id="WAVFileDropDownButton"
              drop="down"
              variant="secondary"
              title={"WAV File"}
              onSelect={handleWAVFileDownload}
            >
              <Dropdown.Item eventKey="14">14 kHz</Dropdown.Item>
              <Dropdown.Item eventKey="19">19 kHz</Dropdown.Item>
            </DropdownButton>
            {/* <img src={WavDownloadButton} alt="Audio file download button" /> */}
            <img
              src={EditButton}
              alt="Edit button"
              onClick={() => handleEditToneTip(selectedToneTip)}
            />
            <span>
              Status:{" "}
              {selectedToneTip?.active ? (
                <b style={{ color: "rgb(255, 137, 41)" }}>Active</b>
              ) : (
                <b style={{ color: "grey" }}>Inactive</b>
              )}
            </span>
          </div>
        </div>
        <div className="preview section">
          <div className="section-title">CARD PREVIEW</div>
          <ToneTipPreviewCard
            notEditable={true}
            isAd={selectedToneTip?.isAd ?? false}
          />
        </div>
      </div>
      <div className="stats section">
        <div className="section-title">STATS</div>
        <ToneTipStatsCard />
      </div>
      {selectedToneTip?.imageCommand && (
        <div className="image-command section">
          <div className="section-title">IMAGE COMMAND INFO</div>
          <div className="info">
            <span>Type: {selectedToneTip.imageCommand.action}</span>
            <span>Value: {selectedToneTip.imageCommand.value}</span>
          </div>
        </div>
      )}
      {selectedToneTip?.tags && selectedToneTip.tags.length > 0 && (
        <div className="section tags">
          <div className="section-title">TAGS</div>
          <ToneTipTagsDisplay tags={selectedToneTip.tags} />
        </div>
      )}
      <div className="commands section">
        <div className="section-title">COMMAND BUTTONS</div>
        <div className="command-btns-container">
          {selectedToneTip?.commands.map((command: CommandI, index) => {
            return (
              <CommandButton
                values={command}
                key={index}
                commandIndex={index}
                uarc={selectedToneTip.id}
              />
            );
          })}
        </div>
      </div>
      <div className="comments section">
        <div className="section-title">
          COMMENTS <img src={GreyBarsIcon} alt="Grey bars" />
        </div>
        {currentUser && selectedToneTip?.id && (
          <AddCommentInput
            onCommentSent={onCommentSent}
            uarc={selectedToneTip.id}
          />
        )}
        {comments &&
          comments.map((comment, index) => {
            return (
              <CommentCard
                comment={comment}
                key={comment.id}
                onEditComment={onCommentEdited}
                onDeleteComment={() => onCommentDeleted(comment.id)}
              />
            );
          })}
        {!comments && "Comments Loading..."}
      </div>
    </div>
  );
}

export default ToneTipDetails;
