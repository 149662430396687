import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { ColorResult, ColorChangeHandler } from "react-color";
import { CommandI } from "../../../Interfaces/ToneTip";
import { PresetColors } from "../EditableCommandButton/EditableCommandButton";

export interface ColorPickerPropsI {
  commandButton: CommandI;
  index: number;
  setCommandButtons: React.Dispatch<React.SetStateAction<Array<CommandI>>>;
  previousColors: PresetColors;
}

const BgColorPicker = (props: ColorPickerPropsI) => {
  const { setCommandButtons, commandButton, previousColors, index } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(commandButton.bgColor);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange: ColorChangeHandler = (color: ColorResult) => {
    setColor(color.hex);
  };

  const handleCommandBgColorChange: ColorChangeHandler = (
    color: ColorResult
  ) => {
    setCommandButtons((prevState: CommandI[]) => {
      const commandButtonsCopy = [...prevState];
      commandButtonsCopy[index].bgColor = color.hex;
      return commandButtonsCopy;
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "30px",
        height: "30px",
        borderRadius: "15px",
        background: `${color}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "5px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "40px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div>
          <div
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
          />
          <SketchPicker
            disableAlpha
            color={color}
            onChange={handleChange}
            onChangeComplete={handleCommandBgColorChange}
            presetColors={previousColors || []}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BgColorPicker;
