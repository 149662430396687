import "./EditableCommandButton.scss";
import GreyWave from "../../assets/img/tone-tip-grey-bg-wave.svg";
import WhiteRedirectArrow from "../../assets/icons/arrow-up-right-icon.svg";
import { useState } from "react";
import UnfoldButton from "../../assets/icons/display-command-options.svg";
import FoldButton from "../../assets/icons/hide-command-options.svg";
import BgColorPicker from "../ColorPicker/BgColorPicker";
import TextColorPicker from "../ColorPicker/TextColorPicker";
import { CommandI } from "../../../Interfaces/ToneTip";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PresetColor } from "react-color/lib/components/sketch/Sketch";
import { checkUrl } from "../../utils/miscellaneous/helpers";

interface EditableCommandButtonPropsI {
  index: number;
  setCommandButtons: React.Dispatch<React.SetStateAction<Array<CommandI>>>;
  commandButtons: Array<CommandI>;
}

export type PresetColors = PresetColor[];

function EditableCommandButton(props: EditableCommandButtonPropsI) {
  const [isValidInput, setisValidInput] = useState(true);
  let { index, setCommandButtons, commandButtons } = props;
  const [editing, setEditing] = useState(false);
  const commandButton = commandButtons[index];
  const previousColorsBg: PresetColors = commandButtons.map((cB, index) => {
    return { title: index.toString(), color: cB.bgColor };
  });
  const previousColorsText: PresetColors = commandButtons.map(
    (cB, index: number) => {
      return { title: index.toString(), color: cB.textColor };
    }
  );

  const renderIcon = () => {
    switch (commandButton.action) {
      case "Website":
        return <FontAwesomeIcon icon={faLink} />;
      case "Location":
        return <FontAwesomeIcon icon={faMapLocationDot} />;
      case "Phone":
        return <FontAwesomeIcon icon={faPhone} />;
      default:
        return "";
    }
  };

  const handleCommandButtonClick = () => {
    setEditing((prevState) => {
      return !prevState;
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { name, value } = e.target;
    if (name === "value" && commandButton.action === "Phone") {
      // If the value is not a number or a dash, don't allow it, reset the value
      if (!value.match(/^[0-9-]*$/)) {
        return;
      }
    }
    if (commandButton.action === "Website" && name === "value") {
      const isValidURL = checkUrl(value);
      if (isValidURL === false) {
        setisValidInput(false);
      } else {
        setisValidInput(true);
        value = isValidURL as string;
      }
    }
    setCommandButtons((prevState: CommandI[]) => {
      const commandButtonsCopy = [...prevState];
      commandButtonsCopy[index] = { ...commandButton, [name]: value };
      return commandButtonsCopy;
    });
  };

  return (
    <div>
      <button
        className="command-btn"
        style={{
          backgroundColor: commandButton?.bgColor,
          color: commandButton?.textColor,
        }}
        onClick={handleCommandButtonClick}
      >
        {renderIcon()}
        {commandButton?.text || `Button ${index.toString()}`}
        <img src={GreyWave} id="grey-wave" />
        <img src={WhiteRedirectArrow} />
        <img
          src={editing ? FoldButton : UnfoldButton}
          id="show-hide-indicator"
        />
      </button>
      {editing && (
        <div className="command-btn-options-container">
          <div className="row">
            <div className="cbo-input-group" style={{ width: "100%" }}>
              <label htmlFor="text">Label</label>
              <input
                name="text"
                id="text"
                type="text"
                value={commandButton.text}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="cbo-input-group">
              <label htmlFor="textColor">Text</label>
              <TextColorPicker
                setCommandButtons={setCommandButtons}
                index={index}
                commandButton={commandButton}
                previousColors={previousColorsText}
              />
            </div>
            <div className="cbo-input-group">
              <label htmlFor="bgColor">Background</label>
              <BgColorPicker
                index={index}
                setCommandButtons={setCommandButtons}
                commandButton={commandButton}
                previousColors={previousColorsBg}
              />
            </div>
            <div className="cbo-input-group">
              <label htmlFor="action">Type</label>
              <select
                value={commandButton.action}
                onChange={handleInputChange}
                name="action"
              >
                <option value="Website">Website</option>
                <option value="Phone">Phone</option>
                <option value="Location">Location</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cbo-input-group" style={{ width: "100%" }}>
              <label htmlFor="value">
                {commandButton.action === "Website"
                  ? `URL ${!isValidInput ? "*Enter a valid URL!" : ""}`
                  : commandButton.action === "Location"
                  ? "Location (just as you would search in Google Maps)"
                  : "Phone number (only numbers and dashes)"}
              </label>
              <input
                style={
                  !isValidInput
                    ? { borderColor: "red", outlineColor: "red" }
                    : {}
                }
                name="value"
                id="value"
                type="text"
                value={commandButton.value}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableCommandButton;
