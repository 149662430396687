import ToneTipPreviewCard from "../ToneTipPreviewCard/ToneTipPreviewCard";
import CloseButton from "../../assets/img/close-btn.svg";
import "./CreateToneTip.scss";
import EditableCommandButton from "../EditableCommandButton/EditableCommandButton";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import OrangeButton from "../OrangeButton/OrangeButton";
import { useUserContext } from "../../context/userContext";
import { CommandI, ImageCommandI, ToneTipI } from "../../../Interfaces/ToneTip";
import AddCommandBtn from "../../assets/icons/add-command-btn.svg";
import DeleteCommandBtn from "../../assets/icons/delete-command-btn.svg";
import { decreaseToneTipsLeft } from "../../services/Users/UserGeneralServices";
import { uploadPicture } from "../../services/StorageUploadServices";
import {
  checkIfToneTipExistsById,
  getLastToneTipTAG,
  uploadToneTip,
} from "../../services/ToneTipServices";
import { useToneTipContext } from "../../context/toneTipsContext";
import { AiOutlineDelete } from "react-icons/ai";
import { FormCheck } from "react-bootstrap";
import ToneTipTagsEditor from "../Tags/ToneTipTagsEditor";
import { Tag } from "react-tag-input/types/components/SingleTag";

const FILE_SIZE = 2000000; // 2 MB
const MINIMUM_COMMANDS = 1;
const DUMMY_COMMAND_BTN: CommandI = {
  action: "Website",
  bgColor: "#0000BB",
  text: "Button",
  textColor: "#ffffff",
  value: "",
  clicks: 0,
};
const DEFAULT_COMMAND_BTNS: Array<CommandI> = Array.from(
  { length: 3 },
  () => DUMMY_COMMAND_BTN
);

const DEFAULT_TT_TITLE = "Title";

function CreateToneTip(props: any) {
  const { handleCreateToneTip, fetchUserToneTips } = useToneTipContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [imagePreview, setImagePreview] = useState<string>("");
  const [toneTipTitle, setToneTipTitle] = useState<string>(DEFAULT_TT_TITLE);
  const [toneTipFileName, setToneTipFileName] = useState("");
  const [imageCommandType, setImageCommandType] =
    useState<ImageCommandI["action"]>("Website");
  const [imageCommandValue, setImageCommandValue] = useState<string>();

  const { currentUser } = useUserContext();
  const availableCommandButtons =
    currentUser?.subType === "Business" ||
    currentUser?.subType === "Enterprise" ||
    currentUser?.userType === "Admin"
      ? 5
      : 3;
  const [commandButtons, setCommandButtons] =
    useState<Array<CommandI>>(DEFAULT_COMMAND_BTNS);
  const [loading, setLoading] = useState<boolean>(false);
  const [commandsChanged, setCommandsChanged] = useState<boolean>(false);
  const [ttStatus, setTtStatus] = useState<boolean>(true);
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true);
  const [isAd, setIsAd] = useState<boolean>(true);
  const [tags, setTags] = useState<Tag[]>([]);

  const resetToDefaults = () => {
    setSelectedFile(null);
    setToneTipFileName("");
    setImagePreview("");
    setImageCommandType("Website");
    setImageCommandValue("");
    setToneTipTitle(DEFAULT_TT_TITLE);
    setCommandButtons(DEFAULT_COMMAND_BTNS);
    setLoading(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadNewToneTip = async () => {
    try {
      setLoading(true);
      if (
        selectedFile &&
        commandsChanged &&
        toneTipTitle !== DEFAULT_TT_TITLE &&
        currentUser?.id &&
        commandButtons.length > 0
      ) {
        if (currentUser.userType !== "Admin") {
          // const toneTipsLeft = await getToneTipsLeft(currentUser.id);
          const toneTipsLeft = currentUser.toneTipsLeft;
          if (!toneTipsLeft || toneTipsLeft <= 0) {
            throw new Error("Please upgrade plan to get additional ToneTips.");
          }
        }
        const newToneTipTAG = await getLastToneTipTAG(
          currentUser.userType === "Admin"
        ); // Here we get the last id provided by firebase
        if (newToneTipTAG) {
          const isTagFree = await checkIfToneTipExistsById(newToneTipTAG);
          if (!isTagFree) {
            throw new Error("Generated TAG already in use, please try again");
          }
          const toneTipAvatarURL: string | null = await uploadPicture(
            selectedFile,
            "tonetipAvatars",
            newToneTipTAG
          );
          if (toneTipAvatarURL) {
            const newToneTip: ToneTipI = {
              active: ttStatus,
              ...(imageCommandType && imageCommandValue
                ? {
                    imageCommand: {
                      action: imageCommandType,
                      value: imageCommandValue,
                    },
                  }
                : {}),
              title: toneTipTitle,
              commentsEnabled: commentsEnabled,
              creator: currentUser.id,
              image: toneTipAvatarURL,
              commands: commandButtons,
              createdAt: new Date(),
              lastEdited: new Date(),
              views: 0,
              impressions: 0,
              likes: 0,
              dislikes: 0,
              favorites: 0,
              comments: 0,
              shares: 0,
              fileName: toneTipFileName,
              isAd: isAd,
              ...(tags.length > 0 ? { tags: tags.map((tag) => tag.text) } : {}),
            };
            await uploadToneTip(newToneTip, newToneTipTAG);
            await decreaseToneTipsLeft(currentUser.id, newToneTipTAG);
            alert("ToneTip uploaded successfully");
            await fetchUserToneTips();
            resetToDefaults();
          }
        }
      } else {
        alert(
          "You need to add a title, an image, and add at least 1 command to your ToneTip"
        );
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const addCommandButton = (): void => {
    if (commandButtons.length < availableCommandButtons) {
      setCommandButtons((prevState) => {
        return [...prevState, DUMMY_COMMAND_BTN];
      });
    }
  };

  const deleteCommandButton = (index?: number): void => {
    if (commandButtons.length === 1) {
      return;
    }
    if (index !== undefined) {
      setCommandButtons((prevState) => {
        const newArray = prevState.filter((_, i) => i !== index);
        return newArray;
      });
      return;
    } else if (commandButtons.length > MINIMUM_COMMANDS) {
      setCommandButtons((prevState) => {
        return prevState.slice(0, -1);
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > FILE_SIZE) {
        alert("File size must be 2 MB or less");
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreview("");
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    // Function to check if the command buttons have been modified
    setCommandsChanged(
      JSON.stringify(commandButtons) !== JSON.stringify(DEFAULT_COMMAND_BTNS)
    );
  }, [commandButtons]);

  function handleTtStatusChange(e: ChangeEvent<HTMLInputElement>): void {
    const value = e.target.checked;
    setTtStatus(value);
  }

  function handleAdvertisementFlagChange(
    e: ChangeEvent<HTMLInputElement>
  ): void {
    const value = e.target.checked;
    setIsAd(value);
  }

  function handleCommentsEnabledChange(e: ChangeEvent<HTMLInputElement>): void {
    const value = e.target.checked;
    setCommentsEnabled(value);
  }

  return (
    <div className="create-tone-tip-container">
      <div className="sticky-container">
        <div className="details-top-part">
          <span className="tone-tip-title">Create a ToneTip</span>
          <div className="tone-tip-action-btns-container">
            <img
              src={CloseButton}
              alt="Close button"
              onClick={() => handleCreateToneTip()}
            />
          </div>
        </div>
        <div className="preview section">
          <div className="section-title">CARD PREVIEW</div>
          <ToneTipPreviewCard
            titleValue={toneTipTitle}
            imagePreview={imagePreview}
            updateImage={handleImageClick}
            isAd={true}
          />
          <div className="image section">
            <div className="upload-image-container">
              <span>
                Image should have 1:1 aspect ratio. Ideal resolution is
                1080x1080 pixels.
              </span>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/jpeg, image/png"
            />
          </div>
        </div>
        <div className="status-change-container">
          <div className="property-switch">
            Status:
            <FormCheck
              type="switch"
              id="status-switch"
              defaultChecked={ttStatus}
              onChange={handleTtStatusChange}
              disabled={loading}
            />
          </div>
          <div className="property-switch">
            Comments:
            <FormCheck
              type="switch"
              id="comments-switch"
              defaultChecked={commentsEnabled}
              onChange={handleCommentsEnabledChange}
              disabled={loading}
            />
          </div>
          <div className="property-switch">
            Ad:
            <FormCheck
              type="switch"
              id="ad-switch"
              defaultChecked={isAd}
              onChange={handleAdvertisementFlagChange}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <div className="title section">
        <div className="section-title">TITLE</div>
        <div className="add-title-container">
          <input
            type="text"
            placeholder="Add title"
            onChange={(e) => setToneTipTitle(e.target.value)}
            value={toneTipTitle}
          />
        </div>
      </div>
      <div className="title section">
        <div className="section-title">FILENAME</div>
        <div className="add-title-container">
          <input
            type="text"
            placeholder="Filename"
            onChange={(e) => setToneTipFileName(e.target.value)}
            value={toneTipFileName}
          />
        </div>
      </div>
      <div className="section tags">
        <ToneTipTagsEditor tags={tags} setTags={setTags} />
      </div>
      {currentUser?.subType === "Business" ||
      currentUser?.subType === "Enterprise" ||
      currentUser?.userType === "Admin" ? (
        <div className="title section">
          <div className="section-title">IMAGE COMMAND</div>
          <div className="add-title-container">
            <input
              type="text"
              placeholder="Add image command value"
              onChange={(e) => setImageCommandValue(e.target.value)}
              value={imageCommandValue}
            />
            <select
              value={imageCommandType}
              onChange={(e) =>
                setImageCommandType(e.target.value as ImageCommandI["action"])
              }
              name="action"
              style={{ marginTop: "10px" }}
            >
              <option value="Website">Website</option>
              <option value="Phone">Phone</option>
              <option value="Location">Location</option>
            </select>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="commands section">
        <div className="section-title">COMMAND BUTTONS</div>
        <div className="command-btns-container">
          {commandButtons.map((commandBtn: CommandI, index: number) => {
            return (
              <div className="single-btn-container">
                {commandButtons.length > 1 && (
                  <AiOutlineDelete
                    className="delete-command-btn"
                    role="button"
                    onClick={() => deleteCommandButton(index)}
                    key={`delete-btn - ${index}`}
                  />
                )}
                <EditableCommandButton
                  key={index}
                  index={index}
                  setCommandButtons={setCommandButtons}
                  commandButtons={commandButtons}
                />
              </div>
            );
          })}
          <div className="add-delete-command-btns-container">
            {commandButtons.length < availableCommandButtons && (
              <button onClick={addCommandButton}>
                <img src={AddCommandBtn} />
              </button>
            )}
            {commandButtons.length > MINIMUM_COMMANDS && (
              <button onClick={() => deleteCommandButton()}>
                <img src={DeleteCommandBtn} />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="actions-section-container">
        <button
          className="dismiss user-info-action-btn"
          type="button"
          // onClick={resetToDefaults}
          onClick={() => handleCreateToneTip()}
        >
          Dismiss
        </button>
        <OrangeButton
          type="button"
          clickEvent={uploadNewToneTip}
          loader={loading}
        >
          Create
        </OrangeButton>
      </div>
    </div>
  );
}

export default CreateToneTip;
