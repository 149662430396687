import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { Tag } from "react-tag-input/types/components/SingleTag";
import "./ToneTipTagsEditor.scss";

const MAX_TAGS = 10;

type ToneTipTagsProps = {
  tags?: Tag[];
  setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
};

const ToneTipTagsEditor = ({ tags = [], setTags }: ToneTipTagsProps) => {
  const handleDelete = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleAddition = (tag: Tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const onTagUpdate = (index: number, newTag: Tag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  const handleTagClick = (index: number) => {
    console.log("The tag at index " + index + " was clicked");
  };

  return (
    <div className="">
      <h1 className="section-title">TAGS</h1>
      <div className="tags-container">
        <ReactTags
          tags={tags}
          inputFieldPosition="top"
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          onTagUpdate={onTagUpdate}
          handleTagClick={handleTagClick}
          editable
          maxTags={MAX_TAGS}
          allowAdditionFromPaste
        />
      </div>
    </div>
  );
};
export default ToneTipTagsEditor;
